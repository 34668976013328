import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout location={typeof window !== 'undefined' ? location : null}>
    <SEO
      title="Error 404 - Cinelíngua francesa"
      description="Page not found"
      pathname="/404/"
    />
    <div className="error404Page">
      <header className="masthead">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-lg-7 my-auto">
              <div className="header-content mx-auto">
                <h1 className="mb-3">Oups, la page n'existe pas !</h1>
                <p className="lead mb-5">Revenir à l'accueil ?</p>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  </Layout>
);

export default NotFoundPage;
